@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --max-width: 1198px;

    --card-rgb: 180, 185, 188;
    --card-border-rgb: 131, 134, 135;

    /* SVG Logos sizes */
    --v2-logo-width: 256px;
    --v2-logo-height: 114px;
    --linkedin-logo-height: 76.8px;
    --youtube-logo-height: 91.5px;
    --github-logo-height: 64px;
    --medium-logo-height: 64px;

    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;

    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;

    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;

    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;

    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;

    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;

    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;

    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  max-width: 100vw;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

area {
  outline-color: black;
}

a {
  color: inherit;
  text-decoration: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

.max-width {
  width: 90%;
  max-width: var(--max-width);
  margin-right: 5%;
  margin-left: 5%;
}

@media (min-width: 1278px) {
  .max-width {
    width: 100%;
    margin-right: 0%;
    margin-left: 0%;
  }
}

/* home page orbiting */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin-reverse {
  to {
    transform: rotate(-360deg);
  }
}

.animate-orbit {
  animation: spin 100s linear infinite;
  position: absolute;
}

.animate-orbit-reverse {
  animation: spin-reverse 100s linear infinite;
  position: absolute;
}
